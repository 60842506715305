.faq {
  min-height: 100vh;
  padding-top: 8rem;
  padding-bottom: 3rem;
  position: relative;
}

.faq .container {
  display: flex;
  position: relative;
}

.faq .container .faqNav {
  flex: 1;
  padding: 2rem;
  position: sticky;
  /* border: solid red 2px; */
}

.faq .container .faqNav .header {
  color: var(--gold-color);
  font-size: xx-large;
}

.faq .container .faqNav .navList {
  margin-top: 2rem;
}

.faq .container .faqNav .navList ul li {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.faq .container .faqNav .navList ul li button {
  color: var(--blue-color);
  display: block;
  background-color: transparent;
  padding: auto;
  cursor: pointer;
  font-size: 1rem;
  break-inside: avoid;
}

.faq .container .faqNav .navList ul li button:hover {
  color: var(--gold-color);
}

.faq .container .faqNav .navList ul li button.selected {
  color: var(--gold-color);
  font-weight: bold;
}

.faq .container .faqContent {
  flex: 4;
  padding: 2rem;
}

.faq .container .faqContent .searchBar {
  margin-bottom: 2rem;
}

.faq .container .faqContent .searchBar input {
  color: var(--blue-color);
  font-weight: 600;
  background-color: #f1f5f6;
  border: 0;
  border-radius: 0.5rem;
  /* border: solid red 2px; */
}

.faq .group-title {
  text-align: start !important;
  color: var(--blue-color);
  font-weight: 500;
}

.faq .faq-title {
  color: var(--blue-color);
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 400;
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .faq .container {
    flex-direction: column;
  }

  .faq .container .faqNav {
    padding-left: 0;
    padding-right: 0;
  }

  .faq .container .faqContent {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .faq .container .faqNav .navList {
    display: none;
  }
}
