.popup-content {
  width: 40%;
}
.pmodal {
  padding: 2rem 1.5rem;
  font-family: "Open Sans", sans-serif, Bookman Old Style;
}

.close-btn {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 2rem;
  color: var(--blue-color);
  line-height: 1;
}

.modal-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--blue-color);
  font-weight: 700;
  text-transform: capitalize;
}

.modal-subtitle {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: var(--gold-color);
  font-weight: 600;
}

.pform .form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.pform .form-group label {
  font-weight: 600;
  color: var(--blue-color);
  margin-bottom: 0.4rem;
}

.pform .form-group input,
.pform .form-group select {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

button.submit-btn {
  background: var(--blue-color);
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 1.5rem;
  display: block !important;
}

button.submit-btn:hover {
  background: #05203b; /* slightly darker shade */
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Adjustments */
@media screen and (max-width: 600px) {
  .pmodal {
    width: 95vw;
    padding: 1.5rem 1rem;
  }

  .modal-title {
    font-size: 1.5rem;
  }

  .modal-subtitle {
    font-size: 0.9rem;
  }
}
