footer {
  min-height: 80vh;
  background-color: var(--blue-color);
  position: relative;
  padding-top: 4rem;
}

footer .container .footerNavigation {
  display: flex;
  gap: 2%;
  padding-bottom: 4rem;
}

footer .container .footerNavigation .left {
  width: 40%;
}

footer .container .footerNavigation .left .companyDetail {
  color: var(--color-white);
}

footer .container .footerNavigation .left .companyDetail .logo img {
  width: 20rem;
}

footer .container .footerNavigation .left .companyDetail .address {
  margin-top: 3rem;
}

footer a.infoContainer {
  text-decoration: none;
  color: inherit;
}

footer a.infoContainer:hover,
footer .container .footerNavigation .right .contacts .phone a:hover p {
  color: var(--gold-color);
}

.address .infoContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.2rem 0;
}

footer .container .footerNavigation .right {
  width: 58%;
  color: var(--color-white);
}

footer .container .footerNavigation .right .contacts {
  display: flex;
  margin-bottom: 5rem;
}

footer .container .footerNavigation .right .contacts .phone {
  width: 50%;
}

footer .container .footerNavigation .right .contacts .phone a p {
  font-size: 2.5rem;
  color: white;
  text-decoration: none;
  font-weight: 600 !important;
}

footer .container .footerNavigation .right .contacts .socials {
  width: 50%;
  display: flex;
  gap: 5%;
}

footer .container .footerNavigation .right .contacts .socials .icons .icon {
  font-size: 3rem;
  color: var(--color-white);
}

footer .container .footerNavigation .right .contacts .socials .icons .icon:hover {
  color: var(--gold-color);
}

footer .container .footerNavigation .right .menuHolder {
  display: flex;
}

footer .container .footerNavigation .right .menuHolder .permalinks {
  width: 50%;
}

footer .container .footerNavigation .right .menuHolder .permalinks .header h2 {
  text-transform: capitalize;
  color: var(--gold-color);
  font-family: Bookman Old Style;
}

footer .container .footerNavigation .right .menuHolder .permalinks .menu {
  display: flex;
  flex-direction: column;
}

footer .container .footerNavigation .right .menuHolder .permalinks .menu .service_menu_item {
  text-transform: capitalize;
  color: var(--color-white);
  margin: 0.5rem 0;
}

footer .container .footerNavigation .right .menuHolder .permalinks .menu .service_menu_item:hover {
  color: var(--light);
}

footer .container .footerNavigation .right .menuHolder .search-col {
  width: 50%;
  display: flex;
  flex-direction: column;
}

footer .container .footerNavigation .right .menuHolder .search-col .search-bar form input[type="text"] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid var(--color-white);
  float: left;
  width: 80%;
  background: #f1f1f1;
}

footer .container .footerNavigation .right .menuHolder .search-col .search-bar form .searchButton {
  float: left;
  width: 12%;
  padding: 4.5px;
  background: var(--gold-color);
  color: var(--color-white);
  font-size: 17px;
  border: 1.6px solid var(--gold-color);
  border-left: none;
  cursor: pointer;
  text-align: center;
}

footer .container .footerNavigation .right .menuHolder .search-col .search-bar form .searchButton .searchIcon {
  width: 2rem;
  height: 1.5rem;
  /* border: solid red 2px; */
}

footer .container .footerNavigation .right .menuHolder .search-col .search-bar form button::after {
  content: "";
  clear: both;
  display: table;
}

footer .container .footerNavigation .right .menuHolder .search-col .text-summary {
  margin-top: 1rem;
}

footer .container .footer-menu {
  border-top: solid white 2px;
  display: flex;
  justify-content: space-between;
  place-items: center;
  text-transform: capitalize;
  font-size: 0.9rem;
  padding: 1rem 0;
}

footer .container .footer-menu {
  border-top: solid white 2px;
  display: flex;
  justify-content: space-between;
  place-items: center;
  text-transform: capitalize;
  font-size: 0.9rem;
  padding: 1rem 0;
}

footer .container .footer-menu .copyright {
  width: 35%;
  justify-content: start;
}

footer .container .footer-menu .copyright .copy-name {
  color: var(--color-white);
}

footer .container .footer-menu .copyright .copy-name a {
  color: var(--color-white);
}

footer .container .footer-menu .copyright .top ul {
  display: flex;
  gap: 5%;
}

footer .container .footer-menu .copyright .top ul li a {
  color: var(--color-white);
}

footer .container .footer-menu .copyright .bottom li a {
  color: var(--color-white);
}

footer .container .footer-menu .menu-items {
  width: 55%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5%;
  place-items: center;
  /* border: solid red 2px; */
}

footer .container .footer-menu .menu-items .menu_item {
  color: var(--color-white);
  /* border: solid red 2px; */
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  footer .container .footerNavigation {
    flex-direction: column;
  }

  footer .container .footerNavigation .left {
    width: 100%;
    padding-bottom: 2rem;
  }

  footer .container .footerNavigation .right {
    width: 100%;
  }

  footer .container .footerNavigation .right .contacts {
    flex-direction: column;
  }

  footer .container .footerNavigation .right .contacts .phone {
    width: 100%;
    margin-bottom: 2rem;
  }

  footer .container .footerNavigation .right .contacts .phone a {
    font-size: 2rem;
  }

  footer .container .footerNavigation .right .contacts .socials {
    width: 100%;
  }

  footer .container .footerNavigation .right .contacts .socials .icons .icon {
    font-size: 2rem;
  }

  footer .container .footerNavigation .right .menuHolder {
    flex-direction: column;
  }

  footer .container .footerNavigation .right .menuHolder .permalinks {
    width: 100%;
  }

  footer .container .footerNavigation .right .menuHolder .search-col {
    width: 100%;
    margin-top: 2rem;
  }

  footer .container .footer-menu {
    justify-content: flex-start;
    place-items: flex-start;
    text-transform: capitalize;
    font-size: 0.9rem;
    padding: 1rem 0;

    flex-direction: column;
  }

  footer .container .footer-menu .copyright {
    width: 100%;
    padding-bottom: 2rem;
  }

  footer .container .footer-menu .copyright .top ul {
    display: flex;
    gap: 5%;
  }

  footer .container .footer-menu .menu-items {
    width: 100%;
    padding: 2rem 0;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    place-items: left;
    /* border: solid red 2px; */

    flex-direction: column;

    display: none;
  }
}