.contactPageSection {
  background-color: var(--color-white);
  min-height: 80vh;
  padding-top: 8rem;
  padding-bottom: 10rem;
  position: relative;
  /* z-index: -2; */
}

.contactPageSection .container {
  z-index: 2;
}

.contactPageSection .container .contactPageHeader {
  padding: 2rem 0;
  margin-bottom: 2rem;
}

.contactPageSection .container .contactPageHeader h1 {
  font-style: normal;
  font-weight: 200;
  font-size: 64px;
  text-transform: capitalize;
  line-height: 4.5rem;
  color: var(--gold-color);
  font-family: Bookman Old Style;
}

.contactPageSection .container .map {
  filter: saturate(0);
}

.contactPageSection .container .map:hover {
  filter: unset;
}

.contactPageSection .container .addressForm {
  display: flex;
  gap: 5%;
  margin-top: 2rem;
}

.contactPageSection .container .addressForm .address {
  width: 50%;
}

.contactPageSection .container .addressForm .contactForm {
  width: 50%;
}

.contactPageSection .container .addressForm .contactForm form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

/* input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid #999;
    resize: none;
    color: #000;
} */

.contactPageSection .container .addressForm .contactForm form button {
  z-index: 2;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* =========== Media Queries (medium Screens) ========== */
@media screen and (max-width: 1024px) {
}

/* =========== Media Queries (Small Screens) ========== */

@media screen and (max-width: 600px) {
  .contactPageSection .container .contactPageHeader h1 {
    font-size: 44px;
    line-height: 2.5rem;
  }

  .contactPageSection .container .addressForm {
    flex-direction: column;
  }

  .contactPageSection .container .addressForm .address {
    width: 100%;
    margin-bottom: 2rem;
  }

  .contactPageSection .container .addressForm .contactForm {
    width: 100%;
  }

  .contactPageSection .container .addressForm .contactForm .btn1 {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .contactSection .txtContent h2 {
    font-size: 2rem;
  }
  .contactSection .txtContent p {
    font-size: 1rem;
  }
}
