.usefulLinks{
    
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
}

.usefulLinks .txtContent h3{
    text-transform: capitalize;
    color: var(--blue-color);
    font-size: 1.8rem;
    font-family: Bookman Old Style;
}

.usefulLinks .linkItem {
    margin-bottom: 1rem;
}

.usefulLinks .linkItem .title{
    color: var(--blue-color);
    font-weight: 800;
}

/* .usefulLinks .linkItem  a{
    color: #741cb7;
} */