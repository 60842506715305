.results ul li .content {
    margin-bottom: 3rem;
}

.results ul li .content .contentHeader h3 {
    color: var(--blue-color);
    font-family: days one;
    font-size: x-large;
    margin-bottom: 1rem;
    font-family: Bookman Old Style;
}