.showcase {
  padding-top: 6rem;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .showcase .hor{
    height: 60vh;
    border: solid green 2px;
} */

.header h1 {
  font-family: Bookman Old Style;
  font-weight: 600;
  font-size: 4rem;
  text-transform: capitalize;
  color: var(--blue-color);
}

.header p {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--blue-color);
}

.showcase .imgWrapper .image {
  width: 100%;
  /* height: 100%; */
  /* border: solid blue 2px; */
}

.aboutSection {
  background-color: var(--gold-color);
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txtContent h2 {
  color: var(--color-white);
  font-size: 2.5rem;
  font-family: Bookman Old Style;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
}

.txtContent p {
  color: var(--blue-color);
  font-size: 1.2rem;
  font-weight: 400;
}

.servicesSection {
  background-color: var(--blue-color);
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
}

.servicesSection .header {
  margin-bottom: 3rem;
}

.servicesSection .header h2 {
  color: var(--color-white);
  text-transform: capitalize;
  font-family: Bookman Old Style;
  font-size: 2.8rem;
}

.servicesSection .serviceCards .hor {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

.testimony {
  background-color: var(--gold-color);
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimony .txtContent {
  padding: 0 10%;
}

.testimony .txtContent p {
  color: var(--color-white);
  text-align: center;
}

.testimony .testifier p {
  font-weight: 600;
  font-family: Bookman Old Style;
}

.whySection {
  padding: 2rem 0;
}

.whySection .txtContent h2 {
  color: var(--gold-color);
  text-transform: capitalize;
  margin-bottom: 2rem;
}

/* .whySection .bodyContent{
    border: solid red 2px;
   
} */
.whySection .bodyContent h4 {
  text-transform: capitalize;
  font-weight: 800;
  font-family: Bookman Old Style;
  font-size: 1.3rem;
}

a.more-link {
  color: var(--gold-color);
  text-decoration: none;
  font-weight: 400;
}

a.more-link:hover {
  color: var(--color-white);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .header h1 {
    font-size: 3rem;
  }

  .header p {
    font-size: 1.3rem;
  }

  .aboutSection .hor {
    flex-direction: column-reverse;
  }

  .servicesSection {
    padding: 2rem 0;
  }

  .servicesSection .header {
    margin-bottom: 1rem;
  }

  .testimony {
    padding: 2rem 0;
  }

  .testimony .txtContent {
    padding: 0;
  }
}
