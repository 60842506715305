.whatwedo {
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.whatwedo h1 {
  color: var(--gold-color);
  font-size: 3rem;
  font-weight: 600;
}

.whatwedo h3 {
  color: var(--blue-color);
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

.whatwedo h5 {
  color: var(--blue-color);
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0;
  font-family: Bookman Old Style;
}

.whatwedo p {
  color: var(--blue-color);
}

.whatwedo .subtitle {
  width: 87%;
}

.whatwedo .circle {
  background-color: var(--white-color);
  border-radius: 50%;
  border: 1px solid var(--blue-color);
  width: 90px;
  height: 90px;
}

.whatwedo .circle2 {
  background-color: var(--blue-color);
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
}

.whatwedo .circle img {
  width: 100%;
  height: 100%;
  margin: auto;
}

.whatwedo .circle2 img {
  width: 60px;
  height: 60px;
  margin: auto;
}

.whatwedo .whatwedoline {
  border-bottom: 1px solid var(--blue-color);
}

.whatwedo .counter h2 {
  float: right;
  font-size: 3rem;
  font-family: Poppins;
  color: var(--gold-color);
  font-weight: 600;
}

@media (max-width: 1024px) {
  .whatwedo p {
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .whatwedo h1 {
    font-size: 2rem;
  }
  .whatwedo h3 {
    color: var(--blue-color);
    font-size: 1.2rem;
  }
}
