#service-card.card {
  min-height: 20rem;
  /* border: solid red 2px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  background-color: transparent;
  border: none;
}

#service-card.card .head {
  color: var(--gold-color);
  font-size: 1.5rem;
  font-family: Bookman Old Style;
  text-transform: capitalize;
  margin-bottom: 1rem;
  min-height: 2rem;
}

#service-card.card .body {
  color: var(--color-white);
  font-size: 1rem;
  min-height: 10rem;
  /* border: solid red 2px; */
}

#service-card.card a {
  justify-self: flex-end;
  /* border: solid red 2px; */
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  #service-card.card {
    min-height: 14rem;
    /* border: solid red 2px; */
    justify-content: unset;
  }

  #service-card.card .head {
    margin-bottom: 0;
    min-height: 1rem;
  }

  #service-card.card .body {
    min-height: 5rem;
    /* border: solid red 2px; */
  }

  #service-card.card a {
    justify-self: flex-end;
    /* border: solid red 2px; */
  }
}
