

.cta-button {
  background-color: #072b4f;
  border-radius: 5rem;
  border: none;
  font-size: 16px;

  /* -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27); */

  overflow: hidden;
  position: relative;

  min-width: 8rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: #000;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  transition: all 400ms ease;
}

.cta-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #0f3d6b;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
 
}


.cta-button:hover {
  color: #e8e8e8;
  transform: scale(0.94);
}

.cta-button:hover::before {
  width: 100%;
}

.blueBkg{
  background-color: var(--blue-color) !important;
}

.whiteBkg{
  background-color: var(--color-white) !important;
  color: var(--blue-color) !important;
}

.whiteBkg::before {
  background-color: #e2e5e7 !important;
}

.goldBkg{
  background-color: var(--gold-color) !important;
  color: var(--color-white) !important;
}

.goldBkg::before {
  background-color: #d6b457 !important;
}