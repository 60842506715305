.navbar {
  width: 100vw;
  height: 5rem;
  position: fixed;
  z-index: 999;
  display: grid;
  place-items: center;
  transform: var(--transition);
  background: var(--blue-color);
  top: 0;
  left: 0;
}

.navScroll {
  right: 0;
  height: 4rem;
  width: 80%;
  margin: 1rem auto 0; /* top margin = 1rem, auto left/right */
  border-radius: 5px;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  border-bottom: solid 1px var(--gold-color);
  transition: all 0.3s ease;
}

.navbar.navScroll .container .nav__items .nav_item::after {
  content: "";
  width: 0;
  height: 3px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--gold-color);
  transition: width 0.3s;
}

.navbar.navScroll .container .nav__items .nav_item:hover::after {
  width: 50%;
}

.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%; /* So items have room within the nav */
  margin: 0 auto;
}

.navbar .container .nav__items {
  display: flex;
  gap: 2rem;
  align-items: center;
  white-space: nowrap;
  /* right: 0 !important; */
  /* flex-basis: 100%; */
  /* justify-content: flex-end; */
  flex-wrap: nowrap;
  margin-left: auto;
}

.navbar .container .nav__items .nav_item {
  color: var(--color-white);
  font-weight: 400;
  font-size: 0.9rem;
  text-transform: capitalize;
  position: relative;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.navbar.navScroll .container .nav__items .nav_item {
  color: var(--color-white);
}

.navbar .container .nav__items .nav_item::after {
  content: "";
  width: 0;
  height: 3px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--gold-color);
  transition: width 0.3s;
}

.navbar .container .nav__items .nav_item:hover::after {
  width: 50%;
}

button {
  display: none;
}

.navbar .container .nav__logo img {
  width: 12rem;
  transition: width 0.3s ease;
}

.navScroll .container .nav__logo img {
  width: 10rem; /* scale down on scroll */
}

.book-appointment-btn {
  padding: 0.75rem 1.5rem;
  background: var(--gold-color);
  color: var(--blue-color);
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 2rem;
  transition: background 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  margin-left: 1rem;
}

.book-appointment-btn:hover {
  background: #967b3d; /* a slightly darker gold shade */
  transform: scale(1.05);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
  .navbar {
    position: fixed;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .navScroll {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .navbar.navScroll .container button {
    color: var(--color-white);
  }

  .navbar .container button {
    display: inline-block;
    background: transparent;
    color: var(--color-white);
    font-size: 1.2rem;
    cursor: pointer;
  }

  .navbar .container .nav__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* background: rgba(255, 255, 255, .55); */
    background-color: var(--gold-color);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(100px);
    border-radius: 15px 15px 15px 15px;
    width: 100%;
    height: auto;
    position: absolute;
    left: -100%;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
    top: 6.9rem;
  }

  .navbar .container .show__nav {
    display: flex;
    left: 0%;
  }

  .navbar .container .hide__nav {
    display: none;
  }

  .navbar .container .nav__items .nav_item {
    color: var(--color-white);
  }

  .navbar.container .nav__items .nav_item {
    display: block;
    padding: 2rem 0;
    width: 100%;
  }

  .navbar .container .nav__logo img {
    width: 10rem;
  }

  .book-appointment-btn {
    background-color: var(--blue-color);
    color: var(--gold-color);
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    margin-left: 0;
  }
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .navbar .container .nav__logo img {
    width: 6rem;
  }

  .navbar .container .nav__items .nav_item::after {
    background: var(--blue-color);
  }

  .navbar.navScroll .container .nav__items .nav_item::after {
    background: var(--blue-color);
  }
}
