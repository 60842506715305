@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

@font-face {
  font-family: "Poppins", Bookman Old Style, sans-serif;
  src: url(./font/bookman-old-style.ttf);
}

@font-face {
  font-family: "Bookman Old Style";
  src: local("Bookman Old Style"),
    url("./font/BOOKOS.TTF") format("truetype");
}

@font-face {
  font-family: "Bookman Old Style";
  src: local("Bookman Old Style"),
    url("./font/BOOKOSB.TTF") format("truetype");
}



* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --gold-color: #af954b;
  --blue-color: #072b4f;
  --color-white: #fff;
  --black-color: #000;
  --transition: all 400ms ease;
  --light-color: #999;
  --grey-light: #f0eff2;
  --dark-color: rgba(var(--black-color), 0.7);
  --container-lg: 82%;
  --container-md: 90%;
  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif, Bookman Old Style;
  line-height: 1.5;
  color: var(--dark-color);
  /* user-select: none; */
}

.container {
  width: var(--container-lg);
  margin: 0 auto;
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

section h2 {
  text-align: center;
}

section p {
  text-align: left;
  width: 42%;
  margin: 0.6rem auto 2.5rem;
  width: 100%;
}

p {
  font-weight: 200;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  background: transparent;
  border: 2px solid #999;
  resize: none;
  color: var(--blue-color);
}

.hor {
  display: flex;
  gap: 2rem;
}

.ver {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: solid red 2px; */
}

.gold {
  color: var(--gold-color) !important;
}

.blue {
  color: var(--blue-color) !important;
}

.btn-black {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: #000;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
}

.btn-black:hover {
  background: lighten(#000, 8%);
  color: #fff;
  box-shadow: 0 1rem 1.6rem rgba(#000, 0.15);
  transform: scale(0.94);
}

.btn-white {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: #fff;
  color: #000;
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
}

.btn-white:hover {
  background: lighten(#fff, 8%);
  color: #000;
  box-shadow: 0 1rem 1.6rem rgba(#fff, 0.15);
  transform: scale(0.94);
}

.btn-blue {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  background: var(--blue-color);
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.1rem;
  cursor: pointer;
  transition: all 400ms ease;
}

.btn-blue:hover {
  background: lighten(#000, 8%);
  color: #fff;
  box-shadow: 0 1rem 1.6rem rgba(#000, 0.15);
  transform: scale(0.94);
}

.btn3 {
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: var(--black-color);
  color: var(--color-white);
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
}

.btn3:hover {
  background: lighten(#000, 8%);
  color: var(--color-white);
  box-shadow: 0 1rem 1.6rem rgba(#000, 0.15);
  transform: scale(0.94);
}

.btn4 {
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: var(--color-white);
  color: var(--black-color);
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
}

.btn4:hover {
  background: lighten(#fff, 8%);
  color: var(--black-color);
  box-shadow: 0 1rem 1.6rem rgba(#fff, 0.15);
  transform: scale(0.94);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
  .container {
    width: 90%;
  }

  section p {
    width: 55%;
  }
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  section p {
    width: 100%;
  }

  .hor {
    flex-direction: column;
    padding: 2rem 0;
  }
}