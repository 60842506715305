.contactSection {
    background-color: var(--gold-color);
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactSection .txtContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15%;
}

.contactSection .txtContent h2 {
    text-transform: uppercase;
}

.contactSection .txtContent p {
    text-align: center;
    color: var(--color-white);
    font-family: Bookman Old Style;
}

@media screen and (max-width: 600px) {
    .contactSection {
        padding: 2rem 0;
    }

    .contactSection .txtContent {
        padding: 0;
    }
}