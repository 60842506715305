.ceo-section {
  padding: 10rem 0 5rem 0;
}

.ceo-section h1,
.our-founder h1,
.our-team h1 {
  color: var(--gold-color);
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  font-family: Poppins;
}

.ceo-section h2 {
  color: var(--blue-color);
  font-size: 2.5rem;
  font-weight: 500;
  text-align: unset;
  font-family: Poppins;
}

.ceo-section .subtitle {
  width: 75%;
}

.ceo-section .ceo-bg {
  background-color: var(--blue-color);
  position: relative;
  height: 550px;
  width: 100%;
}

.ceo-section .ceo-img {
  /* position: absolute;
    top: 3rem;
    left: 3.8rem; */
  /* flip horizontally */
  transform: scaleX(-1);
}

.our-founder h1,
.our-team h1 {
  font-size: 2.3rem;
  text-transform: capitalize;
  font-weight: 600;
}

.our-founder p,
.our-team p {
  color: var(--blue-color);
}

.our-team .card-body {
  flex: unset;
  padding: 0;
  margin: 0;
  height: 370px;
}

.our-team .card-body img {
  height: 100%;
  object-fit: cover;
}

.our-team .card {
  min-height: 24rem;
  justify-content: unset;
}

.our-team .card-foot {
  padding: 1rem 1.4rem;
  background-color: var(--blue-color) !important;
}

.our-team .card-foot h4 {
  color: var(--gold-color);
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 2px;
  font-family: Poppins;
  letter-spacing: 0.1rem;
}

.our-team .card-foot p {
  color: white;
}

/* Clamp the text to 4 lines when not expanded */
.about-desc {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
}

/* Remove clamping when expanded */
.about-desc.expanded {
  -webkit-line-clamp: unset;
}

/* Style for the read more/less link */
.read-more {
  display: inline-block;
  margin-top: 0.5rem;
  color: var(--blue-color);
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .ceo-section {
    padding: 8rem 0 5rem 0;
  }

  .ceo-section p,
  .our-founder p,
  .our-team p {
    width: unset;
  }

  .ceo-section .ceo-bg {
    height: unset;
    width: 100%;

    /* padding: 1rem; */
  }

  .ceo-section .ceo-img {
    position: unset;
  }

  .ceo-section h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .ceo-section h2 {
    font-size: 2.2rem;
  }

  .our-team .card-body {
    height: 500px;
  }

  .our-team .card-foot {
    padding: 2rem 1.4rem;
  }
}

@media screen and (max-width: 480px) {
  .our-team .card-body {
    height: 300px;
  }
  .our-team .card-foot h4 {
    font-size: 1.3rem;
    font-weight: 500;
  }
}
