/* Header Section */
.heading {
  padding: 10rem 0 3rem 0;
  text-align: center;
}

.heading h1 {
  color: var(--gold-color);
  font-size: 2rem;
  font-weight: 600;
}

.heading h2 {
  color: var(--blue-color);
  font-size: 3rem;
  font-weight: 600;
  margin-top: 1rem;
}

.heading p {
  color: var(--dark-color);
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
}
/* Responsive Adjustments */
@media screen and (max-width: 600px) {
  .heading {
    padding: 5rem 0 5rem 0;
  }
}
