.services-grid-section {
  padding: 2rem 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.services-grid {
  display: flex;
  justify-content: center;
}

.service-card {
  cursor: pointer;
  perspective: 1000px;
  min-height: 20rem;
  border-radius: 0;
}

/* Flipcard Styles */
.flipcard {
  width: 100%;
  height: 100%;
}

.flipcard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.service-card:hover .flipcard-inner {
  transform: rotateY(180deg);
}

.flipcard-front,
.flipcard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.flipcard-front {
  background-color: var(--blue-color);
  color: #000;
}

.flipcard-back {
  background-color: var(--gold-color);
  color: #fff;
  transform: rotateY(180deg);
  text-align: center;
}

.service-icon {
  border-radius: 50%;
  background-color: aliceblue;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.service-icon img {
  width: 100%;
  height: auto;
  width: 80px;
  height: 80px;
}

.service-title {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  color: var(--gold-color);
  width: 80%;
  text-transform: capitalize;
}

.service-description {
  font-size: 0.95rem;
  line-height: 1.4;
  margin: 0;
  text-align: center;
}

.load-more-button {
  text-align: center;
  margin-top: 1.5rem;
  cursor: pointer;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(10px);
  }
  20% {
    transform: translateY(0);
  }
}

.load-more-button svg {
  animation: bounce 3s infinite;
}

@media screen and (max-width: 768px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .services-grid {
    grid-template-columns: 1fr;
  }

  .service-icon img {
    width: 50px;
    height: 50px;
  }
}
